<template>
    <div>
      <div class="form-group mb-3">
        <label for="SerialNumber">Серийный номер</label>
        <input
          type="text"
          id="SerialNumber"
          v-model="searchQuery"
          class="form-control"
          :class="{ 'is-invalid': errors.SerialNumber }"
          required
        />
        <div v-if="errors.SerialNumber" class="invalid-feedback">{{ errors.SerialNumber }}</div>
      </div>
  
      <div v-if="loading" class="text-center mt-3">
        <span>Загрузка...</span>
      </div>
  
      <div v-if="devices.length > 0" class="p-2 table-responsive">
        <table class="table table-bordered table-hover table-light">
          <thead>
            <tr>
              <th>Серийный номер</th>
              <th>Модель</th>
              <th>Дата активации</th>
              <th>Дата обслуживания</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="device in devices" :key="device.DeviceID">
              <td>{{ device.SerialNumber }}</td>
              <td>{{ device.ModelName }}</td>
              <td>{{ device.DateOfStart }}</td>
              <td>{{ device.DateOfLastWork }}</td>
              <td>
                <a :href="`/techsup/device/show/${device.DeviceID}`" class="btn btn-primary">Открыть</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="devices.length === 0 && !loading" class="alert alert-warning text-center">
        Нет таких устройств
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { debounce } from "lodash";
  
  export default {
    props: {
      apiRoute: String, // Передача маршрута API
      csrfToken: String,
    },
    data() {
      return {
        searchQuery: "",
        devices: [],
        errors: {},
        loading: false,
      };
    },
    methods: {
      searchClients: debounce(async function () {
        if (!this.searchQuery) {
          this.devices = [];
          return;
        }
  
        this.loading = true;
        this.errors = {};
  
        try {
            const response = await axios.post(this.apiRoute, {
                    SerialNumber: this.searchQuery
                }, {
                headers: {
                    "X-CSRF-TOKEN": this.csrfToken,
                    "Content-Type": "application/json", 
                    "Accept": "application/json"
                }
            });
            this.devices = response.data;
        } catch (error) {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.error("Ошибка запроса:", error);
          }
        } finally {
          this.loading = false;
        }
      }, 500),
    },
    watch: {
      searchQuery() {
        this.searchClients();
      }
    }
  };
  </script>
  