<template>
    <div v-if="isEnded">
        <strong>{{ nameField }}</strong> Закончилась
    </div>
    <div v-else>
        <strong>{{ nameField }} </strong>{{ days }} {{ hours }}
    </div>
</template>

<script>
export default {
    props: {
        endDate: {
            type: String 
        },
        nameField: {
            type: String
        }
    },
    data() {
            return {
                days: null,
                hours: null,
                isEnded: null
            }
        },
    methods: {
        getNoun(number, one, two, five) {
            let n = Math.abs(number);
            n %= 100;
            if (n >= 5 && n <= 20) {
            return five;
            }
            n %= 10;
            if (n === 1) {
            return one;
            }
            if (n >= 2 && n <= 4) {
            return two;
            }
            return five;
        },
        updateRemaining (distance) {
            var num = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.days = num + this.getNoun(num, ' день', ' дня', ' дней');
            num = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.hours = num + this.getNoun(num, ' час', ' часа', ' часов');
        },
        tick () {
            const currentTime = new Date()
            const distance = Math.max(new Date(this.endDate) - currentTime, 0)
            this.updateRemaining(distance)
            if (distance === 0) {
                clearInterval(this.timer)
                this.isEnded = true
            }
        }
    },
    mounted () {
        this.tick()
        this.timer = setInterval(this.tick.bind(this), 1000)
    },
    destroy () {
        clearInterval(this.timer)
    }
};
</script>

<style>

</style>