<template>
    <div>
        <select v-model="selectedModel" @change="fetchParts">
            <option value="">Выберите модель</option>
            <option v-for="model in models" :key="model.ModelID" :value="model.ModelID">{{ model.ModelName }}</option>
        </select>

        <parts-manager :parts="parts" :allParts="allParts" :model-id="selectedModel" :crsf-token="crsfToken"></parts-manager>
    </div>
</template>

<script>
import axios from 'axios';
import PartsManager from './partsManager.vue';

export default {
    props: ['models', 'crsfToken'],
    components: { PartsManager },
    data() {
        return {
            selectedModel: '',
            parts: [],
            allParts: []
        };
    },
    methods: {
        fetchParts() {
            if (this.selectedModel) {
                axios.get(`/api/models/${this.selectedModel}/parts`)
                    .then(response => {
                        this.parts = response.data;
                        this.updateAllParts();
                    })
                    .catch(error => {
                        console.error(error);
                    });

                axios.get(`/api/models/parts`)
                    .then(response => {
                        this.allParts = response.data;
                        this.updateAllParts();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                this.parts = [];
                this.allParts = [];
            }
        },
        updateAllParts() {
            // Remove parts that are in the selected parts from allParts
            this.allParts = this.allParts.filter(part => !this.parts.some(p => p.ID === part.ID));
        }
    }
}
</script>