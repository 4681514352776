/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
import Vue from 'vue';
import draggable from 'vuedraggable';	

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('draggable', draggable);
Vue.component('countdownWarranty', require('./components/device/countdownWarranty.vue').default);
Vue.component('table-model-part-selector', require('./components/model/tableModelPart.vue').default);
Vue.component('form-create-device', require('./components/device/formCreateDevice.vue').default);

const app = new Vue({
	el: '#app'
});