<template>
    <div class="row">
      <!-- Доступные детали -->
      <div class="col-md-6 table-container">
        <h4>Доступные детали</h4>
        <div class="table-wrapper">
          <div class="header-row">
            <span class="table-cell">Номер</span>
            <span class="table-cell">Название</span>
          </div>
          <draggable
            :list="availableParts"
            :group="{ name: 'parts', pull: true, put: true }"
            @change="sortParts"
            @start="onDragStart"
            @end="onDragEnd"
            class="draggable-container"
            tag="div"
          >
            <div v-for="(part, index) in availableParts" :key="part.ID" class="draggable-row">
              <span class="table-cell">{{ part.ID }}</span>
              <span class="table-cell">{{ part.PartName }}</span>
            </div>
          </draggable>
        </div>
      </div>
  
      <!-- Выбранные детали -->
      <div class="col-md-6 table-container">
        <h4>Выбранные детали</h4>
        <div class="table-wrapper">
          <div class="header-row">
            <span class="table-cell">Номер</span>
            <span class="table-cell">Название</span>
          </div>
          <draggable
            :list="selectedParts"
            :group="{ name: 'parts', pull: true, put: true }"
            @start="onDragStart"
            @end="onDragEnd"
            @change="sortParts"
            class="draggable-container"
            tag="div"
          >
            <div v-for="(part, index) in selectedParts" :key="part.ID" class="draggable-row">
              <span class="table-cell">{{ part.ID }}</span>
              <span class="table-cell ">{{ part.PartName }}</span>
            </div>
          </draggable>
        </div>
      </div>
  
      <!-- Форма сохранения -->
      <form @submit.prevent="submitForm">
        <input type="hidden" name="modelId" :value="modelId">
        <input type="hidden" name="selectedParts" :value="JSON.stringify(selectedParts)">
        <button type="submit" class="btn btn-primary">Сохранить</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import draggable from 'vuedraggable';
  
  export default {
    components: { draggable },
    data() {
      return {
        availableParts: [],
        selectedParts: []
      };
    },
    props: ['parts', 'allParts', 'modelId', 'crsfToken'],
    watch: {
      allParts(newAllParts) {
        this.availableParts = newAllParts.slice();
        this.sortParts();
      },
      parts(newParts) {
        this.selectedParts = newParts.slice();
        this.sortParts();
      }
    },
    methods: {
      onDragStart(evt) {
      },
      onDragEnd(evt) {
        this.sortParts();
      },
      sortParts() {
        this.availableParts.sort((a, b) => a.ID - b.ID);
        this.selectedParts.sort((a, b) => a.ID - b.ID);
      },
      submitForm() {
        const modelId = this.modelId;
        const selectedParts = this.selectedParts;
        axios.post('/api/save-parts', {
          parts: selectedParts,
          modelId: modelId
        }, {
            headers: {
                'X-CSRF-TOKEN': this.crsfToken
            }
        }).then(response => {
          console.log('Saved successfully');
          alert('Для данной модели сохранены части!');
        }).catch(error => {
          console.error(error);
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .row {
    display: flex;
    gap: 16px; /* Отступы между колонками, если нужно */
  }
  
  .table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .table-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #ddd; /* Граница для визуального подтверждения */
  }
  
  .header-row {
    display: flex;
  }
  
  .draggable-container {
    flex: 1;
    overflow: auto;
  }
  
  .draggable-row {
    display: flex;
    cursor: move;
  }
  
  .table-cell {
    padding: 8px;
    border: 1px solid #ddd;
    flex: 1;
  }
  </style>