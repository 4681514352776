<template>
    <div>
        <div class="mb-3">
            <label class="form-label">Компоненты</label>
            <div v-if="loading" class="alert alert-warning text-center">Загрузка остатков со склада...</div>
            <div v-else>
                <div v-if="expandedSerialItems.length > 0">
                    <div v-for="(item, index) in expandedSerialItems" :key="index" class="input-group mb-2">
                        <label class="input-group-text">{{ item.nomenclature.nameNomenclature }}</label>
                        <select 
                            v-model="selectedSerials[index]" 
                            class="form-select"
                            :name="`StoragePart[${item.nomenclature.id}_${index}]`"
                        >
                            <option value="" selected>Не выбрано</option>
                            <option 
                                v-for="serial in availableSerials[item.nomenclature.id]" 
                                :key="serial.id" 
                                :value="serial.id"
                                :disabled="isSerialSelected(serial.id, index)"
                            >
                               Серийный номер - {{ serial.serialNumber }}
                            </option>
                        </select>
                    </div>
                    <p v-if="notEnoughStock" class="alert alert-danger text-center">Недостаточно компонентов на складе!</p>
                </div>
                <div v-else>
                    <p class="alert alert-success text-center">Не требуются</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button :disabled="!allSelected || notEnoughStock" type="submit" class="btn btn-primary">Создать</button>
            </div>
            <div class="col">
                <a :href="routeBack" class="btn btn-primary">Назад</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'select-components-from-warehouse',
    props: {
        specification: Object,
        routeBack: String,
        csrf: String
    },
    data() {
        return {
            storage: [],
            selectedSerials: {},
            loading: true,
        };
    },
    computed: {
        expandedSerialItems() {
            if (!this.specification || !Array.isArray(this.specification.items)) {
                return [];
            }
            return this.specification.items.flatMap(item => 
                item?.nomenclature?.hasSerial 
                    ? Array(item.count).fill(item) 
                    : []
            );
        },
        availableSerials() {
            let serialsMap = {};
            for (const item of this.expandedSerialItems) {
                if (!item.nomenclature) continue; // защита от undefined
                let storageEntries = this.storage.filter(s => s.nomenclature?.id === item.nomenclature.id);
                serialsMap[item.nomenclature.id] = storageEntries.map(s => ({
                    id: s.id,
                    serialNumber: s.serialNumber
                }));
            }
            return serialsMap;
        },
        notEnoughStock() {
            return this.expandedSerialItems.some(item => 
                item.nomenclature?.id && 
                (this.availableSerials[item.nomenclature.id]?.length || 0) < item.count
            );
        },
        allSelected() {
            return this.expandedSerialItems.every((_, index) => this.selectedSerials[index]);
        }
    },
    methods: {
        async fetchStorage() {
            try {
                const response = await fetch('/warehouse/storage', {
                    headers: { 'X-CSRF-TOKEN': this.csrf }
                });
                this.storage = await response.json();
            } catch (error) {
                console.error("Ошибка загрузки остатков:", error);
            } finally {
                this.loading = false;
                this.expandedSerialItems.forEach((_, index) => {
                    this.$set(this.selectedSerials, index, '');
                });
            }
        },
        isSerialSelected(serialId, currentIndex) {
            return Object.entries(this.selectedSerials).some(([index, selectedId]) => 
                selectedId === serialId && index != currentIndex
            );
        },
        getDuplicateIndex(item) {
            const sameItems = this.expandedSerialItems.filter(i => i.nomenclature.id === item.nomenclature.id);
            return sameItems.indexOf(item);
        }
    },
    mounted() {
        this.fetchStorage();
    }
};
</script>
<style>
    .input-group-text {
        word-wrap: break-word;
        white-space: normal;
    }
</style>