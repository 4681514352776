<template>
  <form action="/warehouse/add" method="POST">
    <input type="hidden" name="_token" :value="csrfToken">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="row">
          <label>Значение счетчика:</label>
          <br>
          <input
            type="number"
            name="counterValue"
            v-model="counterValue"
          />
        </div>
        <div class="row">
          <label>Версия программного обеспечения:</label>
          <br>
          <select name="selectedVersion" v-model="selectedVersion">
            <option v-for="version in versions" :key="version.VersionID" :value="version.VersionID">
              {{ version.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <label>Модель:</label>
          <br>
          <select name="selectedModel" v-model="selectedModel" @change="updateSelectedList">
            <option v-for="model in models" :key="model.ModelID" :value="model.ModelID">
              {{ model.ModelName }}
            </option>
          </select>
        </div>
        <div v-for="(item, index) in selectedList" :key="item.PartID">
          <label :for="'input_' + item.PartID">{{ item.part.PartName }}: </label>
          <br>
          <input
            :id="'input_' + item.PartID"
            type="text"
            :name="'item_' + item.PartID"
            v-model="item.value"
          />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <button type="submit" class="btn btn-primary">Создать</button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    models: Array,
    versions: Array,
    csrfToken: String,
    oldSelectedModel: String,
    oldItems: Array,
    oldCounterValue: String, // Пропс, который мы будем использовать только для начальной инициализации
    oldSelectedVersion: String,
  },
  data() {
    return {
      selectedVersion: this.oldSelectedVersion || '', // Используем старое значение или пустую строку
      selectedModel: this.oldSelectedModel || '', // Используем старое значение или пустую строку
      selectedList: [], // Список элементов для выбранной модели
      counterValue: this.oldCounterValue || '', // Переименованный внутренний состояние
    };
  },
  mounted() {
    this.updateSelectedList();
  },
  watch: {
    selectedModel: {
      handler(newModel) {
        this.updateSelectedList();
      }
    }
  },
  methods: {
    updateSelectedList() {
      const selectedModelID = Number(this.selectedModel); // Преобразуем в число
      const selectedModelData = this.models.find(
        model => model.ModelID === selectedModelID
      );
      if (selectedModelData) {
        console.log('Old Items All:', this.oldItems);
        this.selectedList = selectedModelData.parts.map(part => {
          const oldItem = this.oldItems.find(old => old.PartID === part.PartID);
          console.log('Part:', part);
          console.log('Old Item:', oldItem);
          return {
            ...part,
            value: oldItem ? oldItem.value : '' // Устанавливаем старое значение или пустую строку
          };
        });
      } else {
        this.selectedList = [];
      }
    }
  }
};

</script>
