<template>
    <div>
      <div class="form-group mb-3">
        <label for="Login">Логин</label>
        <input
          type="text"
          id="Login"
          v-model="searchQuery"
          class="form-control"
          :class="{ 'is-invalid': errors.Login }"
          required
        />
        <div v-if="errors.Login" class="invalid-feedback">{{ errors.Login }}</div>
      </div>
  
      <div v-if="loading" class="text-center mt-3">
        <span>Загрузка...</span>
      </div>
  
      <div v-if="clients.length > 0" class="p-2 table-responsive">
        <table class="table table-bordered table-hover table-light">
          <thead>
            <tr>
              <th>Логин</th>
              <th>Количество устройств</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in clients" :key="client.UserID">
              <td>{{ client.Login }}</td>
              <td>{{ client.countDevice }}</td>
              <td>
                <a :href="`/techsup/client/show/${client.UserID}`" class="btn btn-primary">Открыть</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="clients.length === 0 && !loading" class="alert alert-warning text-center">
        Нет таких клиентов
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { debounce } from "lodash";
  
  export default {
    props: {
      apiRoute: String, // Передача маршрута API
      csrfToken: String,
    },
    data() {
      return {
        searchQuery: "",
        clients: [],
        errors: {},
        loading: false,
      };
    },
    methods: {
      searchClients: debounce(async function () {
        if (!this.searchQuery) {
          this.clients = [];
          return;
        }
  
        this.loading = true;
        this.errors = {};
  
        try {
            const response = await axios.post(this.apiRoute, {
                    Login: this.searchQuery
                }, {
                headers: {
                    "X-CSRF-TOKEN": this.csrfToken,
                    "Content-Type": "application/json", 
                    "Accept": "application/json"
                }
            });
            this.clients = response.data;
        } catch (error) {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.error("Ошибка запроса:", error);
          }
        } finally {
          this.loading = false;
        }
      }, 500),
    },
    watch: {
      searchQuery() {
        this.searchClients();
      }
    }
  };
  </script>
  